<template>
	<v-container fluid>
		<headerVue :title="'Editar equipo de computo'" />
		<v-row>
			<v-col cols="12" style="text-align:center;">
				<v-alert v-if="validateForm"  type="error">Todos los campos son obligatorio</v-alert>
			</v-col>
		</v-row>
		<v-row>
			<v-col v-if="loading" cols="12" style="text-align:center;">
				<v-progress-circular
				:size="70"
				:width="7"
				color="purple"
				indeterminate></v-progress-circular>
			</v-col>
			<v-col cols="12" v-if="!loading">
				<v-card>
					<v-row>
						<v-col cols="1"></v-col>
						<v-col cols="2">{{ $t("serieNumber") }}</v-col>
						<v-col cols="2"><v-text-field v-model="no_serie" label="Número de serie" filled></v-text-field></v-col>
					</v-row>
					<v-row>
						<v-col cols="1"></v-col>
						<v-col cols="2">
							<v-select
								v-model="brand"
								:items="brands"
								item-value="id"
								item-text="nombreDeMarca"
								label="Marcas"></v-select>
						</v-col>
						<v-col cols="2">
							<v-text-field label="Modelo" filled v-model="modelo"></v-text-field>
						</v-col>
						<v-col cols="2">
							<v-select
								v-model="equimentType"
								:items="equimentTypes"
								item-text="nombre"
								label="Tipo de equipo"></v-select>
						</v-col>
						<v-col cols="2">
							<v-select
								v-model="warranty"
								:items="warrantys"
								label="Tiempo de garantía"></v-select>
						</v-col>
						<v-col cols="2">
							<v-menu
							v-model="menu2"
							:close-on-content-click="false"
							:nudge-right="40"
							transition="scale-transition"
							offset-y
							min-width="auto">
								<template v-slot:activator="{ on, attrs }">
									<v-text-field
									v-model="date_pay"
									label="Fecha de compra"
									prepend-icon="mdi-calendar"
									readonly
									v-bind="attrs"
									v-on="on"></v-text-field>
								</template>
								<v-date-picker v-model="date_pay" @input="menu2=false"></v-date-picker>
							</v-menu>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="1"></v-col>
						<v-col cols="2">
							<v-menu
							v-model="menu1"
							:close-on-content-click="false"
							:nudge-right="40"
							transition="scale-transition"
							offset-y
							min-width="auto">
								<template v-slot:activator="{ on, attrs }">
									<v-text-field
									v-model="warranty_end_date"
									label="Fecha fin de garantía"
									prepend-icon="mdi-calendar"
									readonly
									v-bind="attrs"
									v-on="on"></v-text-field>
								</template>
								<v-date-picker v-model="warranty_end_date" @input="menu1=false"></v-date-picker>
							</v-menu>
						</v-col>
						<v-col cols="2">
							<v-text-field filled label="Memoria" v-model="memory"></v-text-field>
						</v-col>
						<v-col cols="2">
							<v-text-field filled label="Tamaño Disco duro" v-model="dd"></v-text-field>
						</v-col>
						<v-col cols="2">
							<v-select
								v-model="hd"
								:items="hds"
								item-text="nombre"
								label="Tipo de disco duro"></v-select>
						</v-col>
					</v-row>
				</v-card>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12" align-self="center">
				<v-btn @click="back">{{ $t("back") }}</v-btn> <v-btn color="primary" @click="update">Actualizar</v-btn></v-col>
		</v-row>
	</v-container>
</template>
<script>
import headerVue from "../../components/header.vue";
import moment from 'moment-timezone';

export default {
	components: {
		headerVue,
	},
	data() {
		return {
			validateForm:false,
			modelo:null,
			brands: [],
			no_serie:null,
			brand: null,
			equipos: [],
			equimentTypes: [],
			equimentType: null,
			hds: [],
			hd: null,
            id: null,
            loading: true,
			warrantys: [],
			warranty: null,
			memory:null,
			dd:null,
			menu1:false,
			menu2:false,
			warranty_end_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
			date_pay: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
		}
	},
	methods: {
		back() {
			this.$router.push("/equipos")
		},
		update(){
			
			this.validateForm = false;
			if( this.no_serie && this.brand && this.modelo && this.equimentType &&
				this.warranty && this.date_pay && this.warranty_end_date && this.memory &&
				this.dd && this.hd
			){

				let brandCurrent = this.brands.filter( b => b.nombreDeMarca == this.brand );

				const headerCurrent = {
					'Content-type': 'application/json;',
					'Access-Control-Allow-Origin': '*',
					'Access-Control-Allow-Credentials': 'true'
				}

				var datePay = moment( this.date_pay ).utc().format();
				var warrantyEndDate = moment( this.warranty_end_date ).utc().format();

				var raw = JSON.stringify({
					"id": this.id,
					"numeroDeSerie": this.no_serie,
					"idMarca": parseInt( brandCurrent[0].id ),
					"modelo": this.modelo,
					"fechaDeCompra": datePay,
					"fechaFinGarantia": warrantyEndDate,
					"tipo": this.equimentType,
					"memoria": this.memory,
					"tamanoDisco": this.dd,
					"tipoDiscoDuro": this.hd,
					"tiempoGarantia": this.warranty
				});
				
				axios.put('https://consubanco.azurewebsites.net/api/CargaEquipoComputo/'+this.id, raw, {
					headers: headerCurrent
				})
				.then((response) => {
					
					this.$router.push("/equipos");
				})
				.catch((error) => {
					console.warn("save => ", error);
				});
			}
			else{

				this.validateForm = true;
			}
		},
		listBrand() {

			const headerCurrent = {
			'Content-type': 'application/json;',
			'Access-Control-Allow-Origin': '*',
			'Access-Control-Allow-Credentials': 'true'
			}

			axios.get( 'https://consubanco.azurewebsites.net/api/Marcas',{
			headers: headerCurrent
			})
			.then(resp => {
				this.brands = resp.data;
				this.loading = false;
			})
			.catch(err => {
				// Handle Error Here
				console.error(err);
			});
		},
		listEquimentType() {

			const headerCurrent = {
				'Content-type': 'application/json;',
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Credentials': 'true'
			}

			axios.get( 'https://consubanco.azurewebsites.net/api/EquipoTipo',{
				headers: headerCurrent
			})
			.then(resp => {
				this.equimentTypes = resp.data;
				this.loading = false;
			})
			.catch(err => {
				// Handle Error Here
				console.error(err);
			});
		},
		listHD() {

			const headerCurrent = {
				'Content-type': 'application/json;',
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Credentials': 'true'
			}

			axios.get( 'https://consubanco.azurewebsites.net/api/TipoDiscoDuro',{
				headers: headerCurrent
			})
			.then(resp => {
				this.hds = resp.data;
				this.loading = false;
			})
			.catch(err => {
				// Handle Error Here
				console.error(err);
			});
		}
	},
	created() {

        this.id = this.$route.params.id;
		
		axios.get( 'https://consubanco.azurewebsites.net/api/CargaEquipoComputo/'+this.id )
		.then(resp => {
			
			this.no_serie = resp.data.numeroDeSerie;
            
            this.warranty_end_date = moment( resp.data.fechaFinGarantia ).format( "YYYY-MM-DD" );
			this.date_pay = moment( resp.data.fechaDeCompra ).format( "YYYY-MM-DD" );

            this.modelo = resp.data.modelo;
            this.equimentType = resp.data.tipo;
            this.warranty = resp.data.tiempoGarantia;
            this.memory = resp.data.memoria;
            this.dd = resp.data.tamanoDisco;
            this.hd = resp.data.tipoDiscoDuro;
			this.brand = resp.data.idMarca;
			this.loading = false;
		})
		.catch(err => {
			
			console.error( err );
		});

		this.listBrand();
		this.listEquimentType();
		this.listHD();

		for( var g = 1; g < 51; g++ ){
			this.warrantys.push( g );
		}
	},
}
</script>